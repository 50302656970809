// (C) Copyright 2021-2022 Hewlett Packard Enterprise Development LP

import { getManifest } from '../data';

// e.g. '/api/ca/endpoint' => ['', 'api', 'ca', 'endpoint'] => 'ca'
export const getProxyBasename = (url) => url.split('/')[2];
export const getProxy = (url) => (
  getManifest().proxies?.find(({ from }) => from === getProxyBasename(url))
);
export const createProxyUrl = (url) => {
  const { from, to } = getProxy(url);
  return url.replace(`/api/${from}`, `/api/${to}`);
};

export const monkeyPatch = (object, key, callback) => {
  const api = object[key];
  // eslint-disable-next-line no-param-reassign, func-names
  object[key] = function () {
    // eslint-disable-next-line prefer-rest-params
    return api.apply(this, callback(arguments));
  };
};

export const createInterceptors = () => {
  monkeyPatch(
    window,
    'fetch',
    ([url, ...args]) => (
      getProxy(url) ? [createProxyUrl(url), ...args] : [url, ...args]
    ),
  );
  monkeyPatch(
    XMLHttpRequest.prototype,
    'open',
    ([method, url, ...args]) => (
      getProxy(url) ? [method, createProxyUrl(url), ...args] : [method, url, ...args]
    ),
  );
};

export const overridesParser = (overrides, formatter = (i) => i) => (
  overrides?.split(',').map((override) => {
    const [from, ...to] = override.split(':');
    // Re-join on ":" to support previews/proxies using ":" in the value
    return formatter({ from, to: to.join(':') });
  })
);

export const getAppsWithPreviews = (previews, apps) => {
  // e.g. iterable = [["some-app", { preview: "some-branch" }]]
  const iterable = overridesParser(previews, ({ from, to }) => [from, { preview: to }]);
  const previewsMap = new Map(iterable);

  return apps.map((app) => ({
    ...app,
    ...previewsMap.get(app.name),
  }));
};

export const getParamValue = (param) => {
  const searchParamValue = new URLSearchParams(window.location.search).get(param);

  if (searchParamValue) {
    sessionStorage.setItem(param, searchParamValue);
    return searchParamValue;
  }

  return sessionStorage.getItem(param);
};
