// (C) Copyright 2021-2022 Hewlett Packard Enterprise Development LP

import {
  getAppNames,
  triggerAppChange,
  getMountedApps,
  navigateToUrl,
} from 'single-spa';
import {
  ENDPOINTS,
  TENANT_STORAGE_KEY,
  DEEP_LINK_STORAGE_KEY,
  HUB_CHOOSER_PATH,
  APPS,
} from './constants';
import {
  getAppData,
  mergeAppData,
  getApps,
  setManifest,
} from './data';
import { handleOverrides } from './overrides';
import { addRemoteEntry, getRemote } from './remotes';

const ROOT_EXCEPTION_PAGE = '/public/exception';

export const handleApiResponse = async (response) => {
  const { ok, status, statusText } = response;
  const parsedResponse = await response.json();
  if (!ok) {
    sessionStorage.setItem('root-exception', `${status} ${statusText}: ${parsedResponse.message}`);
    return navigateToUrl(ROOT_EXCEPTION_PAGE);
  }
  return parsedResponse;
};

export const getData = async (url) => {
  const { accessToken: { accessToken } } = JSON.parse(sessionStorage.getItem('okta-token-storage'));
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  const response = await fetch(url, config);
  return handleApiResponse(response);
};

export const getTenantPermissions = async () => {
  try {
    const { members } = await getData(ENDPOINTS.PERMISSIONS);
    return members;
  } catch (error) {
    console.error(`Error fetching tenant permissions: ${error}`);
    return [];
  }
};

export const getSpacePermissions = async (spaceId) => {
  try {
    const { members } = await getData(`${ENDPOINTS.PERMISSIONS}?spaceId=${spaceId}`);
    return members;
  } catch (error) {
    console.error(`Error fetching space permissions: ${error}`);
    return [];
  }
};

export const getSpaces = async () => {
  try {
    const { members } = await getData(ENDPOINTS.SPACES);
    return members;
  } catch (error) {
    console.error(`Error fetching spaces: ${error}`);
    return [];
  }
};

export const validTenant = () => {
  const params = new URLSearchParams(window.location.search);
  const tenant = JSON.parse(
    sessionStorage.getItem(TENANT_STORAGE_KEY),
  )?.find(({ selected }) => selected);

  const tenantIdParam = params.get('tenantId');

  /**
   * Is valid when there is a tenant selected, tenantId query param is missing
   * (e.g. use what's in session storage) or when the tenantId query param does
   * not match the selected tenant in session
   */
  return tenant && (!tenantIdParam || tenantIdParam === tenant?.tenantId);
};

export const tenantSelected = () => JSON.parse(
  sessionStorage.getItem(TENANT_STORAGE_KEY),
)?.some(({ selected }) => selected);

export const tenantSessionExists = () => (
  tenantSelected() && JSON.parse(sessionStorage.getItem('okta-token-storage'))?.accessToken // tokens are set
);

export const getSpaceId = (spaces) => {
  const selectedSpace = spaces.find(({ selected }) => selected)?.id;
  const spaceParamId = new URLSearchParams(window.location.search).get('spaceId');
  const isValidSpaceParam = spaces?.some(({ id }) => id === spaceParamId);
  const defaultSpace = spaces.find(({ name }) => name === 'Default')?.id;
  const [firstSpace] = spaces;
  return (isValidSpaceParam && spaceParamId) || selectedSpace || defaultSpace || firstSpace?.id;
};

export const formatSpaces = (spaces) => {
  const selectedSpaceId = getSpaceId(spaces);
  return spaces
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((space) => ({
      ...space,
      selected: selectedSpaceId === space.id,
    }));
};

export const getUserData = () => {
  const { idToken } = JSON.parse(sessionStorage.getItem('okta-token-storage'));
  return {
    id: idToken.claims.sub,
    email: idToken.claims.email,
    name: idToken.claims.name,
    uri: `users/${idToken.claims.sub}`,
  };
};

export const fetchCoreData = async () => {
  const [tenantPermissions, spaces] = await Promise.all([
    getTenantPermissions(),
    getSpaces(),
  ]);

  const spaceId = getSpaceId(spaces);
  const spacePermissions = await getSpacePermissions(spaceId);

  return {
    user: getUserData(),
    permissions: {
      tenant: tenantPermissions,
      space: spacePermissions,
    },
    spaces: formatSpaces(spaces),
    tenants: JSON.parse(sessionStorage.getItem(TENANT_STORAGE_KEY)),
  };
};

export const updateSearchParams = () => {
  const appData = getAppData();
  if (appData) {
    const { search } = window.location;
    const { tenants, spaces } = appData;
    const params = new URLSearchParams(search);
    const spaceId = getSpaceId(spaces);
    const { tenantId } = tenants.find(({ selected }) => selected);
    const tenantIdParam = params.get('tenantId');
    const spaceIdParam = params.get('spaceId');

    const tenantIdHasChanged = tenantIdParam !== tenantId;
    const spaceIdHasChanged = spaceId && spaceIdParam !== spaceId;

    if (tenantIdHasChanged) {
      params.set('tenantId', tenantId);
    }
    if (spaceIdHasChanged) {
      params.set('spaceId', spaceId);
    }
    if (tenantIdHasChanged || spaceIdHasChanged) {
      const { location: { origin, pathname }, history: { state } } = window;
      const url = `${origin}${pathname}?${decodeURIComponent(params.toString())}`;
      window.history.replaceState(state, '', url);
    }
  }
};

export const initPrivateApps = async () => {
  try {
    mergeAppData(await fetchCoreData());
    updateSearchParams();
    // Force single spa to re-evaluate the applications
    triggerAppChange();
  } catch (e) {
    navigateToUrl(ROOT_EXCEPTION_PAGE);
  }
};

export const shouldLogin = () => {
  const { location: { pathname } } = window;
  const requestedHub = pathname.startsWith('/session/hub');
  const publicRoutes = getApps().filter((app) => app.public).map(({ route }) => route);
  const isAccessingPublicPath = publicRoutes.some(
    (route) => pathname.startsWith(route),
  );

  return !requestedHub && !validTenant() && !isAccessingPublicPath;
};

export const redirectToLogin = () => {
  sessionStorage.removeItem('okta-token-storage');
  sessionStorage.removeItem('tenants');
  sessionStorage.setItem(DEEP_LINK_STORAGE_KEY, `${window.location.pathname}${window.location.search}`);
  window.location.assign(`${window.location.origin}${HUB_CHOOSER_PATH}`);
};

export const getAllRoutes = () => getApps()
  .filter(({ name, route }) => route && getAppNames().includes(name))
  .map(({ route }) => route);

export const appRouteMatchesPath = (pathname) => (route) => {
  const [, app] = pathname.split('/');
  return route.toLowerCase() === `/${app.toLowerCase()}`;
};

export const currentAppIsPublic = () => {
  const appName = getMountedApps().find((name) => name !== 'shell');
  if (!appName) {
    return true;
  }
  const app = getApps().find(({ name }) => appName === name);
  return !!app.public;
};

export const appRouteDoesntMatchPath = (pathname) => {
  const matchesPath = appRouteMatchesPath(pathname);
  const allRoutes = getAllRoutes();
  return !allRoutes.some(matchesPath);
};

export const getActiveWhen = ({
  name,
  route,
  default: isDefaultApp,
  public: isPublicApp,
}) => {
  const activeWhenMap = {
    shell: () => getAppData() && !currentAppIsPublic(),
    default: ({ pathname }) => getAppData() && appRouteDoesntMatchPath(pathname),
    private: ({ pathname }) => getAppData() && appRouteMatchesPath(pathname)(route),
    public: ({ pathname }) => appRouteMatchesPath(pathname)(route),
  };

  if (activeWhenMap[name]) {
    return activeWhenMap[name];
  } if (isDefaultApp) {
    return activeWhenMap.default;
  }
  return isPublicApp ? activeWhenMap.public : activeWhenMap.private;
};

const setSpace = async (spaceId) => {
  const { spaces, permissions } = getAppData();
  mergeAppData({
    spaces: spaces.map((space) => ({
      ...space,
      ...{ selected: spaceId === space.id },
    })),
    permissions: {
      ...permissions,
      space: await getSpacePermissions(spaceId),
    },
  });
};

const getCustomProps = () => {
  // TODO When space switcher moves out of saturn_prod app this needs to change
  const appDataMap = {
    'saturn-prod': { setSpace },
  };

  return (app) => ({
    ...getAppData(),
    ...appDataMap[app],
  });
};

export const addRemoteIfMissing = async (app) => {
  if (!getRemote(app)) {
    try {
      await addRemoteEntry(app);
    } catch {
      console.warn(`Remote Entry Error: unable to load ${app.name} remoteEntry.js`);
    }
  }
};

export const getAppFromRemote = (app) => async () => {
  await addRemoteIfMissing(app);
  const factory = await getRemote(app).get('.');
  return factory(); // returns esm module
};

export const getSingleSpaApps = () => getApps().map((app) => ({
  name: app.name,
  activeWhen: getActiveWhen(app),
  app: getAppFromRemote(app),
  customProps: getCustomProps(),
}));

export const setAppName = ({ appsByNewStatus: { MOUNTED } }) => {
  // Find the current app that is being mounted and pass it in
  const mountingApps = MOUNTED.filter((app) => app !== 'shell');
  if (mountingApps.length) {
    const [mountingApp] = mountingApps;
    const app = getApps().find(({ name }) => mountingApp === name);
    if (getAppData()) {
      mergeAppData({ app });
    }
  }
};

export const fetchManifest = async () => {
  const response = await fetch('/manifest.json');
  const manifest = await response.json();
  setManifest(handleOverrides(manifest));
};

export const loadAppFromPath = ({ path }) => {
  const apps = getApps();
  // find the first app that matches, and if there is no match, then find the default app
  const app = apps.find(({ route }) => path.startsWith(`${route}/`)) || apps.find(({ default: defaultApp }) => defaultApp);
  getAppFromRemote(app)();
};

export const handleAppChange = (event) => {
  setAppName(event);
  const { appsByNewStatus: { NOT_MOUNTED, MOUNTED } } = event;
  // In order to achieve the true encapsulation of apps, we need to refresh
  // between the loading of apps to ensure that there are no global pollutants.
  const { SHELL, SESSION } = APPS;
  const isShell = [...MOUNTED, ...NOT_MOUNTED].includes(SHELL);
  const isSession = [...MOUNTED, ...NOT_MOUNTED].includes(SESSION);
  // Do not trigger the refresh when the shell or session is being affected
  const shouldRefresh = NOT_MOUNTED.length && !isSession && !isShell;
  if (shouldRefresh) {
    updateSearchParams();
    window.location.reload();
  }
};
