// (C) Copyright 2021-2022 Hewlett Packard Enterprise Development LP

import { REMOTE_PREFIX } from './constants';

const getRemoteSrc = ({ name, origin, preview }) => {
  const location = preview ? `${name}/preview/${preview}` : name;
  return `${origin || window.location.origin}/ui/${location}/remoteEntry.js`;
};

export const addRemoteEntry = ({ name, origin, preview }) => new Promise((resolve, reject) => {
  const element = Object.assign(document.createElement('script'), {
    // NOTE: The origin must also be whitelisted in the CSP header
    src: getRemoteSrc({ name, origin, preview }),
    type: 'text/javascript',
    async: true,
    onload: resolve,
    onerror: reject,
  });

  document.head.appendChild(element);
});

export const getRemote = ({ name }) => {
  // Note: implicit contract with saturn cli's module federation plugin remote name
  const normalizedAppName = name.replace(/-/g, '_');
  return window[`${REMOTE_PREFIX}${normalizedAppName}`];
};
