// (C) Copyright 2021-2022 Hewlett Packard Enterprise Development LP

import {
  IS_INTEGRATION,
  PREVIEWS,
  PROXIES,
} from '../constants';
import {
  createInterceptors, getAppsWithPreviews, getParamValue, overridesParser,
} from './utils';

export const handleOverrides = (manifest) => {
  if (IS_INTEGRATION) {
    const previews = getParamValue(PREVIEWS);
    const proxies = getParamValue(PROXIES);
    if (previews || proxies) {
      if (proxies) {
        createInterceptors();
      }
      const { apps } = manifest;
      return {
        apps: previews ? getAppsWithPreviews(previews, apps) : apps,
        proxies: proxies ? overridesParser(proxies) : [],
      };
    }
  }
  return manifest;
};
