// (C) Copyright 2021-2022 Hewlett Packard Enterprise Development LP

import {
  triggerListener,
  UPDATE_APP_DATA,
} from '@saturn/events';

let appData;
let manifest;

export const setAppData = (data) => {
  appData = data;
  // This event is picked up by the shell to update the current app name for the header,
  // as the shell never gets updated custom props because it is always mounted
  triggerListener(UPDATE_APP_DATA, { data: appData });
};

export const mergeAppData = (data) => {
  appData = { ...appData, ...data };
  triggerListener(UPDATE_APP_DATA, { data: appData });
};

export const getAppData = () => appData;

export const setManifest = (_manifest) => {
  manifest = _manifest;
};

export const getManifest = () => manifest;

export const getApps = () => manifest.apps;
