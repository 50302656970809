// (C) Copyright 2021-2022 Hewlett Packard Enterprise Development LP

const API_PROXIES = {
  IAM: '/api/iam',
};

export const ENDPOINTS = {
  PERMISSIONS: `${API_PROXIES.IAM}/v1alpha2/assigned-permissions`,
  SPACES: `${API_PROXIES.IAM}/v1alpha2/assigned-spaces`,
};

export const IS_INTEGRATION = window.location.origin === 'https://client.greenlake.hpe-gl-intg.com';
export const PREVIEWS = 'previews';
export const PROXIES = 'proxies';
export const POPSTATE = 'popstate';
export const SINGLE_SPA_BEFORE_APP_CHANGE = 'single-spa:before-app-change';
export const SINGLE_SPA_APP_CHANGE = 'single-spa:app-change';
export const TENANT_STORAGE_KEY = 'tenants';
export const REMOTE_PREFIX = 'saturn_';
export const DEEP_LINK_STORAGE_KEY = 'desiredDeepLink';
export const HUB_CHOOSER_PATH = '/session/hub/choose';

export const APPS = {
  SHELL: 'shell',
  SESSION: 'session',
};
