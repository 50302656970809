// (C) Copyright 2021-2022 Hewlett Packard Enterprise Development LP

import {
  registerListener,
  POST_LOGIN_EVENT,
  PREFETCH_EVENT,
} from '@saturn/events';
import {
  addErrorHandler,
  getAppStatus,
  LOAD_ERROR,
  triggerAppChange,
  unregisterApplication,
} from 'single-spa';
import {
  POPSTATE,
  SINGLE_SPA_BEFORE_APP_CHANGE,
  SINGLE_SPA_APP_CHANGE,
} from './constants';
import {
  initPrivateApps,
  updateSearchParams,
  loadAppFromPath,
  handleAppChange,
} from './utils';

/**
 * Respond to an event dispatched from the session app. This lets the root know when the spoke
 * has been logged into so that we can fetch the core data needed by "private" apps: permissions,
 * spaces, and tenants.
 */
registerListener(POST_LOGIN_EVENT, initPrivateApps);

/**
 * When a new app mounts, trigger an event to let the shell header know the display name of the
 * newly rendered app.
 */
registerListener(SINGLE_SPA_BEFORE_APP_CHANGE, handleAppChange);

/**
 * Listener for history events to update the URL search parameters for spaceId and tenantId.
 */
registerListener(POPSTATE, updateSearchParams);

/**
 * Ensure that the activeWhen functions are being respected when a new app mounts
 * This is to address inconsistencies with how Single SPA evaluates those functions
 * for the shell
 */
registerListener(SINGLE_SPA_APP_CHANGE, triggerAppChange);

/**
 * Trigger a load of the target app; for prefetching
 */
registerListener(PREFETCH_EVENT, loadAppFromPath);

/**
 * When a remoteEntry.js file fails to load, just load the default app instead
 */
addErrorHandler(async ({ appOrParcelName }) => {
  if (getAppStatus(appOrParcelName) === LOAD_ERROR) {
    console.error(`The ${appOrParcelName} app failed to load`);
    await unregisterApplication(appOrParcelName);
  }
});
