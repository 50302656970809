// (C) Copyright 2021-2022 Hewlett Packard Enterprise Development LP

import {
  registerApplication,
  start,
} from 'single-spa';
import {
  constructLayoutEngine,
  constructRoutes,
} from 'single-spa-layout';
import './lifecycle';
import {
  initPrivateApps,
  redirectToLogin,
  tenantSessionExists,
  shouldLogin,
  getSingleSpaApps,
  fetchManifest,
} from './utils';

(async () => {
  await fetchManifest();
  const applications = getSingleSpaApps();

  // TODO: figure out why session app doesn't get removed when we remove this...
  constructLayoutEngine({ routes: constructRoutes({ routes: [] }), applications }).activate();
  applications.forEach(registerApplication);

  start();

  // Note: if none of these conditions are met, wait until the session app
  // triggers an event (saturn:post-login) before initializing.
  if (shouldLogin()) {
    redirectToLogin();
  } else if (tenantSessionExists()) {
    initPrivateApps();
  }
})();
